import Button from 'antd/es/button'
import styled from 'styled-components'

import Text from '@typography/Text'

export const PlayButton = styled(Button)`
  height: 80px;
  width: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: ${p => p.theme.colors.primary75};
  display: grid;
  place-items: center;
  outline: none;
  border: none;

  svg {
    fill: ${p => p.theme.colors.buttonPlay};
  }
`

export const VideoPlayerContainer = styled.div`
  overflow: hidden;
  position: relative;

  iframe {
    border-width: 0;
  }

  &:hover {
    ${PlayButton} {
      &,
      &:hover {
        background: ${p => p.theme.colors.primary};
      }
    }
  }
`

export const Description = styled(Text)`
  position: absolute;
  bottom: 19px;
  inset-inline: 24px auto;
  color: ${p => p.theme.colors.white};
`

export const PerformanceBlock = styled.div<{ $isShow?: boolean; $isShowDescription: boolean }>`
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  transition: opacity 2.3s ease, transform 0.1s 1s;
  opacity: ${p => (p?.$isShow ? '1' : '0')};
  transform: translateY(${p => (p?.$isShow ? '0' : '800px')});
  left: 0;
  top: 0;

  &:before {
    display: ${p => (p?.$isShowDescription ? 'block' : 'none')};
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
