import styled from 'styled-components'

import { Description, VideoPlayerContainer } from '@base/VideoPlayer/styles'

export const Container = styled.section`
  position: relative;
  display: grid;
  grid-template-columns: calc(53% - 48px) 47%;
  justify-content: end;
  grid-gap: 48px;

  ${VideoPlayerContainer} {
    height: 380px;
    border-radius: ${p => p.theme.borderRadius.default};

    ${p => p.theme.mediaQueries.mobile} {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
      border-radius: 0;

      ${Description} {
        inset-inline: auto 24px;
      }
    }
  }

  ${p => p.theme.mediaQueries.mobileTablet} {
    grid-template-columns: calc(60% - 48px) 40%;
  }

  ${p => p.theme.mediaQueries.mobile} {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
`

export const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${p => p.theme.mediaQueries.mobile} {
    height: auto;
  }
`
