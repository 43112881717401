import { type FC, memo } from 'react'

import { BlocksKeys } from '@redux/features/homePage/constants/blockHomePage.constants'

import type { AboutWithVideoType } from '@Types/blocks/aboutWithVideo'
import type { ExtendBlocksType } from '@Types/blocks/blocks'
import VideoPlayer from '@base/VideoPlayer'
import ButtonSearch from '@components/mainPageBlocks/AboutWithVideo/components/ButtonSearch'
import Description from '@components/mainPageBlocks/AboutWithVideo/components/Description'
import { sizes } from '@themes/sizes'
import { getThumbnailFromYoutube } from '@utils/getThumbnailFromYoutube/getThumbnailFromYoutube'

import { Block, Container } from './styles'

type Props = {
  data: ExtendBlocksType
}

const AboutWithVideo: FC<Props> = ({ data }) => {
  const { body, title, video, video_description } = data as AboutWithVideoType

  return (
    <Container data-block={BlocksKeys.ABOUT_WITH_VIDEO}>
      <VideoPlayer
        description={video_description}
        imgSrc={getThumbnailFromYoutube(video)}
        sizes={`(max-width: ${sizes.mobileMaxWidth}px) 100vw, 600px`}
        videoSrc={video}
      />
      <Block>
        <Description body={body} title={title} />
        <ButtonSearch />
      </Block>
    </Container>
  )
}

export default memo(AboutWithVideo)
