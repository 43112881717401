import Image from 'next/image'
import type { FC, SyntheticEvent } from 'react'
import { memo, useEffect, useRef, useState } from 'react'

import Iframe from '@base/Iframe'
import PlayIcon from '@iconscout/unicons/svg/line/play.svg'

import { Description, PerformanceBlock, PlayButton, VideoPlayerContainer } from './styles'

type Props = {
  description?: string
  imgSrc?: string
  isStopped?: boolean
  onClickVideoButton?: (e: SyntheticEvent) => void
  sizes: string
  videoSrc: string
}

const VideoPlayer: FC<Props> = ({ description, imgSrc, isStopped, onClickVideoButton, sizes, videoSrc }) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const performanceRef = useRef<HTMLDivElement | null>(null)

  const handlePlayClick = () => {
    setVideoPlaying(true)
  }

  useEffect(() => {
    isStopped && setVideoPlaying(false)
  }, [isStopped])

  return (
    <VideoPlayerContainer>
      {videoPlaying && <Iframe src={videoSrc} />}
      <PerformanceBlock
        $isShow={!videoPlaying}
        $isShowDescription={!!description}
        onClick={onClickVideoButton || handlePlayClick}
        ref={performanceRef}
      >
        <Image alt="video" fill loading="lazy" quality={60} sizes={sizes} src={imgSrc || ''} />
        <PlayButton aria-label="play video button">
          <PlayIcon />
        </PlayButton>
        {description && <Description size="s">{description}</Description>}
      </PerformanceBlock>
    </VideoPlayerContainer>
  )
}

export default memo(VideoPlayer)
